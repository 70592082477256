.profile-picture {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.profile-picture img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 3px solid var(--blue-green);
}