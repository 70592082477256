@import './_variables.css';
@import './_fonts.css';
@import './_typography.css';
@import './_colours.css';

body {
    align-items: center;
    background: linear-gradient(145deg, #0A3D62, #145b93);
    color: var(--white);
    display: flex;
    height: 100vh;
    justify-content: center;
    margin: 0;
    font-size: 1rem;
} 







