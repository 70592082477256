.contact-item {
    display: inline-flex;
    align-items: center;
    padding: 5px;
  }
  
  .contact-icon {
    margin-right: 15px;
    font-size: 1.2rem; 
    color: var(--blue-green);
  }
  
  .contact-text {
    margin-right: 10px;
    color: #ffffff;
    font-size: var(--font-size-small);
  }
  
  .contact-link {
    margin-left: auto;
    font-size: 1.2rem; 
  }