h1 {
    font-size: var(--font-size-h1);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h);
}

h2 {
    font-size: var(--font-size-h2);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h);
}

h3 {
    font-size: var(--font-size-h3);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h);
}

h4 {
    font-size: var(--font-size-h4);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-h);
}

h5 {
    font-size: var(--font-size-h5);
    font-weight: var(--font-weight-semi-bold);
    line-height: var(--line-height-h);
}

h6 {
    font-size: var(--font-size-h6);
    font-weight: var(--font-weight-semi-bold);
    line-height: var(--line-height-h);
}

p {
    font-size: var(--font-size-p);
    font-weight: var(--font-weight-normal);
    line-height: var(--line-height-p);
}

.font-large {
    font-size: var(--font-size-large);
    font-weight: var(--font-weight-bold);
}

.font-medium {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-bold);
}

.font-small {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-normal);
}

.font-xsmall {
    font-size: var(--font-size-xsmall);
    font-weight: var(--font-weight-normal);
}