.btn-profile {
    padding: 10px 20px;
    border-radius: 20px;
    width: 190px;
}

.primary {
    background-color: var(--blue-green);
    color: var(--white);
    border: 2px solid var(--blue-green);
}

.primary:hover {
    background-color: var(--indigo-dye);
}

.secondary {
    background-color: var(--indigo-dye);
    color: var(--white);
    border: 2px solid var(--blue-green);
}

.secondary:hover {
    background-color: var(--blue-green);
}

.icon {
    margin-left: 10px;
}