.button {
    border: none;
    cursor: pointer;
    font-family: "Titillium Web", sans-serif;
    font-weight: normal;
    letter-spacing: 0.5px;
    margin: 5px;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}