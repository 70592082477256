:root {
    --font-size-h1: 2.5rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.75rem;
    --font-size-h4: 1.5rem;
    --font-size-h5: 1.25rem;
    --font-size-h6: 1.0rem;
    --font-size-p: 1rem;

    --font-size-large: 2rem;
    --font-size-medium: 1.5rem;
    --font-size-small: 1rem;
    --font-size-xsmall: 0.875rem;

    --font-weight-bold: bold;
    --font-weight-semi-bold: 600;
    --font-weight-normal: normal;

    --line-height-h: 0.5;
    --line-height-p: 0.5;
}